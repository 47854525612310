import * as styles from '../styles/courses.module.scss';

import CourseCard from '../components/CourseCard';
import Layout from '../components/Layout';
import React from 'react';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import SectionTitle from '../components/SectionTitle';
import Seo from '../components/SEO';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

const Dance = ({ data }) => {
  const danceCourses = data.danceCourses.nodes;

  return (
    <Layout>
      <Seo title={'Pilart - Danza'} description={'Scopri i nostri corsi di danza!'} />
      <SectionTitle
        title="CORSI DI DANZA"
        subtitle={
          "Aumentare  flessibilità, agilità e  coordinazione e sviluppare le potenzialità espressive e creative di ciascuno: questo è l'obiettivo dei corsi di danza proposti da PILART per ogni età"
        }
      />
      <div className={styles.courses}>
        {danceCourses.map((course) => {
          const featuredImage = getImage(course.frontmatter?.featuredImg?.childImageSharp?.gatsbyImageData);

          return (
            <CourseCard
              key={course.frontmatter?.slug}
              featuredImage={featuredImage}
              title={course.frontmatter?.title}
              // description={course.frontmatter?.description}
              slug={course.frontmatter?.slug}
              type={course.frontmatter?.type}
            />
          );
        })}
      </div>
      <ScrollToTopButton />
    </Layout>
  );
};

export default Dance;

export const query = graphql`
  query DancePage {
    danceCourses: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "dance-course" } } }
      sort: { order: ASC, fields: frontmatter___title }
    ) {
      nodes {
        frontmatter {
          type
          title
          slug
          featuredImg {
            childImageSharp {
              gatsbyImageData(width: 400, height: 300, quality: 90, placeholder: BLURRED, formats: AUTO)
            }
          }
        }
        id
      }
    }
  }
`;
